import React, { useEffect } from 'react'
import Title from '../Title'
import { useParams } from 'react-router-dom'
import laureats from '../Prix/laureats.json'
import './Laureat.scss'

export default function Laureat() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  let { id } = useParams()
  const currentLaureat = laureats.find((laureat) => laureat.id === id)
  return (
    <div id="laureat">
      <Title>
        {`${currentLaureat.name} - Lauréat du Prix Marion Bourgine ${currentLaureat.year} `}
      </Title>
      <div className="profile grid-container">
        <div className="col-12">
          <h2 className="profile__name">{currentLaureat.name}</h2>
          <div className="profile__info">
            <h4>
              Prix Marion Bourgine {currentLaureat.year} <br />
              {currentLaureat.instrument}
            </h4>
          </div>
        </div>
        <div className="col-12">
          <img
            src={`../laureats/profiles/${currentLaureat.id}.jpg`}
            className="profile__pic"
            alt={currentLaureat.name}
          />
        </div>
        <div className="col-12">
          <img
            src={`../laureats/presse/${currentLaureat.id}.jpg`}
            className="profile__press"
            alt={`${currentLaureat.name} - Dans la presse`}
          />
        </div>
      </div>
    </div>
  )
}
