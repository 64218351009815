import React from 'react'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
import Title from '../Title'
import Laureat from '../Laureat'
import './Prix.scss'
import logo11x from './photos/logo.jpg'
import logo12x from './photos/logo@2x.jpg'
import photo11x from './photos/photo1.jpg'
import photo12x from './photos/photo1@2x.jpg'
import photo21x from './photos/photo2.jpg'
import photo22x from './photos/photo2@2x.jpg'
import ambigram11x from './photos/ambigram.jpg'
import ambigram12x from './photos/ambigram@2x.jpg'
import laureats from './laureats.json'

export default function Prix() {
  let { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <div id="prix">
          <Title>Prix Marion Bourgine</Title>
          <div className="grid-container">
            <div className="col-12">
              <h2>Prix Marion Bourgine</h2>
            </div>
            <div className="col-9">
              <h3>L'association</h3>
              <p>
                Pour ne pas nous figer dans le chagrin, ni dans les souvenirs,
                ce que Marion n’aurait pas apprécié, mais continuer des choses
                nouvelles, nous avons choisi de faire un petit clin d’œil à des
                jeunes que nous ne connaissons pas, que Marion n’a pas connus
                mais qui s’intéressent à des choses qu’elle-même aimait. Nous,
                ses amis, sa famille, tous ceux qui ont aimé Marion, faisons
                vivre le souvenir de Marion à travers cette association et le
                prix qu’elle décerne chaque année
              </p>
            </div>
            <div className="col-3">
              <img
                src={logo11x}
                srcSet={logo11x + ' 1x,' + logo12x + ' 2x'}
                alt="Marion Bourgine - Prix Marion Bourgine Marciac"
              />
            </div>
            <div className="col-7">
              <h3>Marion et Marciac</h3>
              <p>
                Marion adorait la musique. Elle avait une formation de
                violoniste classique commencée très jeune en primaire en classes
                musicales à horaires aménagés à Versailles. Elle s’était mise, à
                l’adolescence, au saxophone classique au conservatoire de
                Versailles, en complément du violon. Et puis elle avait
                découvert le jazz, le jazz et Marciac qui étaient devenus une
                passion. Le saxo et le violon restaient ses instruments favoris,
                même si le piano et la contrebasse étaient dans le paysage. Elle
                venait aussi de découvrir la nouvelle chanson française et elle
                était ravie de découvrir et de faire découvrir aux amis de
                nouveaux talents émergents : mes souliers sont rouges, etc Elle
                se promettait de fréquenter régulièrement le festival de
                Luxeuil. Elle découvrait aussi les musiques du monde : le violon
                indien, le djribou, le xylophone vietnamien, le baton de pluie
              </p>
            </div>
            <div className="col-5">
              <img
                src={photo11x}
                srcSet={photo11x + ' 1x,' + photo12x + ' 2x'}
                alt="Marion Bourgine - Prix Marion Bourgine Marciac"
              />
            </div>
            <div className="col-12">
              <h3>Prix Marion Bourgine</h3>
              <p>
                Marion aimait Marciac, elle y a fait des Master class de saxo et
                elle avait aussi été bénévole au festival plusieurs années. Nous
                avons proposé au festival de Marciac de donner un prix
                d'encouragement pour un(e) jeune musicien(ne) montrant à la fois
                son goût pour le jazz et des qualités humaines notables. Le
                Festival de Marciac a très généreusement décidé de s'associer à
                ce souvenir de Marion et à l'encouragement de jeunes talents. A
                partir de 2019, le prix s'est transformé. En effet, l'Astrada, à
                présent en responsabilité des master classes, a généreusement
                souhaité participer au prix . Le montant est surtout symbolique,
                l'association Les amis de Marion Bourgine, le Festival de
                Marciac et l’Astrada donnent chacun 500 €. €. Le prix remis au
                lauréat est à présent de 1500€. Ce sont les professeurs des
                Master Class de Marciac qui décernent le prix.
              </p>
              <br />
              <div className="col-12">
                <img
                  src={photo21x}
                  srcSet={photo21x + ' 1x,' + photo22x + ' 2x'}
                  alt="Marion Bourgine - Prix Marion Bourgine Marciac"
                />
              </div>
              <br />

              <p>
                L’Association remercie tous ceux qui l’ont aidée et ont
                participé à sa remise dans la cour du collège de Marciac, qui
                l’ont aidée aussi à organiser pendant plus de 8 ans un concert à
                Paris des lauréats sur différentes salles de jazz et à venir aux
                premiers concerts des lauréats tout particulièrement lorsqu'ils
                se produisaient à Paris. Bien souvent des élèves de Master
                Classes venaient nombreux participer à ces concerts de Paris.
                Elle remercie tout particulièrement tous ceux et celles qui ont
                fait le discours de l'association lors de la remise du prix:
                Nicolle Mathys, Douglas Hofstadter, Alphane Roy, Marie-Evelyne
                Pellissari, Catherine Voison.
              </p>
              <p>
                L'Association remercie tous ceux au festival de Marciac qui ont
                contribué à lancer le prix, Jean-Louis Guilhaumon qui a toujours
                soutenu si fermement l'initiative commune, Jean-Claude Lasserre
                qui a pris la suite de Jean-Louis Guilhaumon dans la remise du
                prix, Sylviane Laroppe qui s'est toujours occupée des master
                classes au sein du Festival puis en 2019 au sein de l'Astrada,
                Fanny Pagès qui a engagé la participation de l'Astrada au prix.
                Il faut encore mentionner l'aide si précieuse de Jérôme Lenoir
                puis de Alice Fave pour l'annonce du prix chaque année sous le
                grand chapiteau.
              </p>
              <p>
                L'Association remercie tous ceux au festival de Marciac qui font
                connaître le prix auprès de la presse locale, régionale et
                nationale, l'aide si précieuse de Marie Cha chaque année depuis
                le début et, plus récemment, de Cristelle Morandin de l'Astrada.
                Ce fut une belle histoire de couvrir le prix d'abord avec l'aide
                des journalistes régionaux de Jazz in Marciac, de la Dépêche et
                de Sud-ouest, puis avec les radios nationales dont France
                Musique ou FIP ou France Inter et, en 2018, avec France 3 de
                Midi Pyrénées. Nos remerciements vont ici tout particulièrement
                à Alex Dutilh, à Julien Dellifiori et à Elsa Boublil de Radio
                France comme à Christine Ravier de France 3 Midi Pyrénées.
              </p>
              <br />
              <br />
              <br />

              <div className="col-12">
                <img
                  src={ambigram11x}
                  srcSet={ambigram11x + ' 1x,' + ambigram12x + ' 2x'}
                  alt="Marion Bourgine - Prix Marion Bourgine Marciac - Ambigramme de Douglas Hofstadter"
                />
                <br />
                <br />
                <br />

                <p>
                  Douglas Hofstadter, prix Pulitzer, a réalisé pour nous cet
                  ambigramme lorsqu’il est venu en 2011 pour faire le discours
                  de l’association pour la remise du prix. Un ambigramme est un
                  exercice de calligraphie. Celui-ci est un ambigramme pivotant
                  qui conserve la signification quand on fait pivoter le support
                  de 180°. De beaux exemples se trouve dans l’article de
                  Wikipedia.
                </p>
              </div>
            </div>
          </div>
          <div className="laureats__list grid-container">
            <div className="col-12">
              <h3>Les lauréats</h3>
              <p>
                Au fil des années, nous sommes heureux et fiers d’avoir pu
                rencontrer ces jeunes musiciens, de jeunes talents, brillants et
                associés à des personnalités riches, qui présentent toutes, les
                qualités humaines d’ouverture et d’amitié, d’exigence et de
                passion, que nous recherchions. Notre avis est partagé, car
                chaque année, l'annonce du prix, est saluée par un long tonnerre
                d'applaudissement des stagiaires des master class. Nous vous
                invitons tous les suivre et à les encourager. Cliquer sur chaque
                photo, permet d’accéder à l’article de presse le concernant.
              </p>
            </div>
            {laureats.map((laureat) => (
              <div className="laureats__profile col-2" key={laureat.id}>
                <Link to={`${url}/laureats-${laureat.id}`}>
                  <img
                    src={`./laureats/profiles/${laureat.id}.jpg`}
                    className="laureats__profile__pic"
                    alt={laureat.name}
                  />
                  <div className="laureats__profile__name">{laureat.name}</div>
                  <div className="laureats__profile__year">
                    {`${laureat.year} `}
                  </div>
                  <div className="laureats__profile__instrument">
                    {` ${laureat.instrument}`}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Route>
      <Route path={`${path}/laureats-:id`}>
        <Laureat />
      </Route>
    </Switch>
  )
}
