import React, { Component } from 'react'
import Title from '../Title'
import './Legal.scss'

export default class Legal extends Component {
  render() {
    return (
      <div id="legal">
        <Title>Mentions légales</Title>
        <div className="grid-container">
          <div className="col-12">
            <h2>Mentions légales</h2>
            <p>Les Amis de Marion Bourgine</p>
            <p>2 Square Adanson, 75005 Paris</p>
            <br />
            <p>
              <em>Directeur/trice de publication : </em>
            </p>
            <p>Régine Teulier </p>
            <p>01 47 07 14 80</p>
            <br />
            <p>
              <em>Hébergeur :</em>
            </p>
            <p>OVH – 2 rue Kellermann - 59100 Roubaix - France</p>
          </div>
        </div>
      </div>
    )
  }
}
