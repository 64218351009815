import React, { Component } from 'react'
import Title from '../Title'
import './Home.scss'
import profilePic from './marion-bourgine.png'

export default class Home extends Component {
  render() {
    return (
      <div id="home">
        <Title></Title>
        <div className="grid-container">
          <div className="col-12">
            <h2>Don’t worry, be happy !</h2>
          </div>
          <div className="col-12">
            <p>
              Ce site est fait à la mémoire de Marion, nous espérons qu'il lui
              ressemble.
            </p>
          </div>
          <div className="col-4">
            <p className="left">
              Au-delà du chagrin de l’avoir perdue, nous voulons nous souvenir
              d’elle, de l’amour qu’elle nous a donné. Et nous voulons le faire
              dans la célébration de la vitalité, de l’énergie et de la joie de
              vivre qui étaient les siennes.
            </p>
          </div>
          <div className="col-4">
            <img src={profilePic} alt="Marion Bourgine" />
          </div>
          <div className="col-4">
            <p className="right">
              Marion était profondément libre de ses choix, de ses engagements,
              chacun de nous, à travers le lien unique qu’il avait avec elle,
              garde une petite parcelle de Marion.
            </p>
          </div>
          <div className="col-12">
            <div className="last">
              <p>
                Ce sont ces facettes de Marion que nous voulons partager, pour
                continuer, en fidélité et amour pour elle, le chemin que nous
                avions commencé avec elle.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
