import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

export default class Title extends Component {
  render() {
    const defaultTitle = process.env.REACT_APP_NAME
    return (
      <Helmet>
        <title>
          {this.props.children
            ? `${this.props.children} - ${defaultTitle}`
            : defaultTitle}
        </title>
      </Helmet>
    )
  }
}
