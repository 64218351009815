import React, { Component } from 'react'
import './404.scss'

export default class NotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <section id="NotFound">
        <h1>404</h1>
        <h2>Page not found</h2>
      </section>
    )
  }
}
