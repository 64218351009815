import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from './logo.png'
import './Header.scss'
import './MenuBtn.scss'
import './Nav.scss'

export default class Header extends Component {
  state = {
    isToggle: false,
  }
  toggleMenu = () => {
    this.setState({ isToggle: !this.state.isToggle })
  }
  render() {
    let menuClass = this.state.isToggle ? ' nav-open' : ''
    return (
      <div>
        <header>
          <div className="grid-container ">
            <Link to="/">
              <h1>
                <img id="logo" src={Logo} alt="Les Amis de Marions" />
              </h1>
            </Link>
            <nav className={`nav${menuClass}`}>
              <NavLink
                exact
                to="/"
                className="nav__item"
                activeClassName="nav__item-active"
              >
                Accueil
              </NavLink>
              <NavLink
                to="/qui-etait-marion"
                className="nav__item"
                activeClassName="nav__item-active"
              >
                Qui était Marion ?
              </NavLink>
              <NavLink
                to="/ce-quelle-nous-a-apporte"
                className="nav__item"
                activeClassName="nav__item-active"
              >
                Ce qu'elle nous a apporté
              </NavLink>
              <NavLink
                to="/prix-marion-bourgine"
                className="nav__item"
                activeClassName="nav__item-active"
              >
                Prix Marion Bourgine
              </NavLink>
            </nav>
          </div>
        </header>
        <div
          className={`btnMenu ${this.state.isToggle ? 'btnMenu__active' : ''}`}
          onClick={this.toggleMenu}
        >
          <div className="btnMenu__hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="btnMenu__cross">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    )
  }
}
