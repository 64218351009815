import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import soleil from './soleil.jpg'
import './Footer.scss'

export default class Footer extends Component {
  static propTypes = {
    sun: PropTypes.bool,
  }
  static defaultProps = {
    sun: false,
  }
  render() {
    const { sun } = this.props
    return (
      <footer>
        <div className="grid-container">
          {sun ? (
            <div className="col-12">
              <img src={soleil} alt="Soleil - Marion Bourgine" />
            </div>
          ) : (
            ''
          )}
          <div className="col-12">
            <Link to="/legal">Mentions légales</Link>
          </div>
        </div>
      </footer>
    )
  }
}
