import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Header from '../Header'
import Footer from '../Footer'
import Home from '../Home'
import About from '../About'
import Apport from '../Apport'
import Prix from '../Prix'
import Legal from '../Legal'
import NotFound from '../404'

export default class App extends Component {
  componentDidMount() {
    AOS.init({
      once: true, // whether animation should happen only once - while scrolling down
    })
  }
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/qui-etait-marion">
              <About />
            </Route>
            <Route path="/ce-quelle-nous-a-apporte">
              <Apport />
            </Route>
            <Route path="/prix-marion-bourgine">
              <Prix />
            </Route>
            <Route path="/legal">
              <Legal />
            </Route>
            <Route component={NotFound} />
          </Switch>
          <Footer sun={true} />
        </div>
      </Router>
    )
  }
}
