import React, { Component } from 'react'
import Title from '../Title'
import './About.scss'
import photo11x from './photos/photo1.jpg'
import photo12x from './photos/photo1@2x.jpg'
import photo21x from './photos/photo2.jpg'
import photo22x from './photos/photo2@2x.jpg'
import photo31x from './photos/photo3.jpg'
import photo32x from './photos/photo3@2x.jpg'
import photo41x from './photos/photo4.jpg'
import photo42x from './photos/photo4@2x.jpg'
import photo51x from './photos/photo5.jpg'
import photo52x from './photos/photo5@2x.jpg'
import photo61x from './photos/photo6.jpg'
import photo62x from './photos/photo6@2x.jpg'
import photo71x from './photos/photo7.jpg'
import photo72x from './photos/photo7@2x.jpg'

export default class About extends Component {
  render() {
    return (
      <div id="about">
        <Title>Qui était Marion ?</Title>
        <div className="grid-container">
          <div className="col-12">
            <h2>Qui était Marion ?</h2>
          </div>
          <div className="col-7">
            <p>
              Marion était un drôle de mélange entre une fille très réfléchie,
              très construite et un joyeux lutin très spontané, farceur et
              aimant rire et faire la fête. Elle pouvait être timide et réservée
              aussi bien que exubérante et démonstrative. Elle pouvait se
              concentrer sur des maths ou une dissert pendant des heures, mais
              elle aimait le ski, les chiens de traineau, la peinture et la
              musique. Marion était tout cela à la fois, mais surtout elle avait
              le goût et le sens de contacts humains. Elle avait déjà compris
              qu’il faut prendre le temps de dire aux gens qu’on aime qu’ils
              comptent pour nous et elle savait le faire.
            </p>
          </div>
          <div className="col-5">
            <img
              src={photo11x}
              srcSet={photo11x + ' 1x,' + photo12x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>

          <div className="col-8">
            <img
              src={photo21x}
              srcSet={photo21x + ' 1x,' + photo22x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>
          <div className="col-4">
            <img
              src={photo31x}
              srcSet={photo31x + ' 1x,' + photo32x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>

          <div className="col-6">
            <img
              src={photo41x}
              srcSet={photo41x + ' 1x,' + photo42x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>
          <div className="col-6">
            <img
              src={photo51x}
              srcSet={photo51x + ' 1x,' + photo52x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>

          <div className="col-4">
            <img
              src={photo61x}
              srcSet={photo61x + ' 1x,' + photo62x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>
          <div className="col-8">
            <img
              src={photo71x}
              srcSet={photo71x + ' 1x,' + photo72x + ' 2x'}
              alt="Marion Bourgine - Qui était Marion ?"
            />
          </div>
        </div>
      </div>
    )
  }
}
