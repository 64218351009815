import React, { Component } from 'react'
import Title from '../Title'
import './Apport.scss'
import photo11x from './photos/photo1.jpg'
import photo12x from './photos/photo1@2x.jpg'
import photo21x from './photos/photo2.jpg'
import photo22x from './photos/photo2@2x.jpg'
import photo31x from './photos/photo3.jpg'
import photo32x from './photos/photo3@2x.jpg'
import photo41x from './photos/photo4.jpg'
import photo42x from './photos/photo4@2x.jpg'
import photo51x from './photos/photo5.jpg'
import photo52x from './photos/photo5@2x.jpg'
import photo61x from './photos/photo6.jpg'
import photo62x from './photos/photo6@2x.jpg'
import photo71x from './photos/photo7.jpg'
import photo72x from './photos/photo7@2x.jpg'
import photo81x from './photos/photo8.jpg'
import photo82x from './photos/photo8@2x.jpg'

export default class Apport extends Component {
  render() {
    return (
      <div id="apport">
        <Title>Ce qu'elle nous a apporté</Title>
        <div className="grid-container">
          <div className="col-12">
            <h2>Ce qu'elle nous a apporté</h2>
          </div>
          <div className="col-12">
            <p>
              Elle a apporté beaucoup à plusieurs d’entre nous par son
              rayonnement, son écoute profondément attentive, sa façon de
              « cultiver » ses amitiés, ses liens affectifs et de consacrer à
              chacun du temps malgré son planning … toujours ultra chargé …
              « comme celui d’un ministre » lui avait-on dit à l’âge de dix ans,
              ce qui la faisait rire …Le message d’absence qui était sur son
              portable les derniers mois disait : « Eh non, je ne suis pas là,
              alors vous savez quoi faire … »
            </p>
          </div>
          <div className="col-12">
            <img
              src={photo11x}
              srcSet={photo11x + ' 1x,' + photo12x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>
          <div className="col-7">
            <p>
              Chacun de nous retiendra quelque chose de spécial de Marion,
              justement parce qu’elle avait un lien très spécifique avec chacun
              d’entre nous. Elle réfléchissait toujours beaucoup pour faire des
              cadeaux très adaptés, elle ne voulait pas perdre le contact avec
              les amis qu’elle n’avait plus l’occasion de croiser régulièrement
              et elle prenait soin de les revoir même de loin en loin. Peut-on
              oublier ce qui était presque une devise pour elle : Don’t worry,
              be happy !!!! Elle nous a transmis une part de sa vitalité et de
              sa lumière, de son attention aux autres, de sa passion pour toutes
              les facettes de la vie que ce soit la passion intellectuelle, le
              plaisir du sport (mais dans de beaux paysages !! c’était dur de
              faire du jogging sur les trottoirs d’Orsay !!) le goût des arts,
              le sens de la fête … Ce qu’elle était, ce qu’elle avait choisi
              d’être continue à nous parler : vivez pleinement, faites des tas
              de choses, mais prenez le temps de caresser un petit lapin ou de
              vous émerveiller des étonnements d’un enfant, faites à fond des
              choses sérieuses comme les maths et des choses qui le sont moins
              comme de peindre des cadres en bois pour vos amis, cherchez les
              meilleures formations et astreignez-vous à apprendre mais chahutez
              comme une gamine, découvrez le monde mais restez fidèle aux lieux
              qui sont importants pour vous… etc … alors vous savez quoi faire …
            </p>
          </div>
          <div className="col-5">
            <img
              src={photo21x}
              srcSet={photo21x + ' 1x,' + photo22x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
            <img
              src={photo31x}
              srcSet={photo31x + ' 1x,' + photo32x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>
          <div className="col-6">
            <img
              src={photo41x}
              srcSet={photo41x + ' 1x,' + photo42x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>{' '}
          <div className="col-6">
            <img
              src={photo51x}
              srcSet={photo51x + ' 1x,' + photo52x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>{' '}
          <div className="col-6">
            <img
              src={photo61x}
              srcSet={photo61x + ' 1x,' + photo62x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>{' '}
          <div className="col-6">
            <img
              src={photo71x}
              srcSet={photo71x + ' 1x,' + photo72x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>{' '}
          <div className="col-12">
            <img
              src={photo81x}
              srcSet={photo81x + ' 1x,' + photo82x + ' 2x'}
              alt="Marion Bourgine - Ce qu'elle nous a apporté"
            />
          </div>
        </div>
      </div>
    )
  }
}
